import { DesktopNav } from '../components/moodle/common/desktopNav';
import { Header } from '../components/moodle/common/commonHeader';
import { RightNav } from '../components/moodle/common/rightNav';
import { TypeMCQ } from '../components/moodle/mcq/mcq';
import { TypeTrueFalse } from '../components/moodle/mcq/truefalse';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useIndexedDB } from 'react-indexed-db-hook';
import { SubmitMCQ } from '../requests/moodle/submitMCQRequest';
import { ExamSaved } from '../components/common/examSaved';
import { ExamFailed } from '../components/common/examSubmitted';
import { ExamSubmitted } from '../components/common/examSubmitted';
import { ButtonsModal } from '../components/common/modalBtn';
import { RequestLoader } from '../components/common/requestLoader';
import { ToogleQuestion } from '../components/moodle/mcq/toogleQuestion';
import moment from 'moment';

export const SubmitMCQPage = () => {
  window.document.title = 'Submit MCQ';

  const [code, setCode] = useState();
  const [quizid, setQuiz] = useState();
  const [questions, setQuestions] = useState();
  const [qIndex, setQIndex] = useState(0);
  const [answer, setAnswers] = useState([]);
  const [attemptid, setAttempt] = useState();

  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [show, setShow] = useState();
  const [failed, setFailed] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const { getByID } = useIndexedDB('dashboard');
  const { add, update, deleteRecord } = useIndexedDB('exams');
  const { getByID: getExam } = useIndexedDB('exams');

  const examSaved = document.getElementById('savedClick');
  const examFailed = document.getElementById('failedClick');
  const examSubmitted = document.getElementById('submittedClick');

  const UpdateAnswers = (id) => {
    getExam(id).then((data) => {
      if (data) {
        setAnswers((prev) => data.answers);
      }
    });
  };

  useEffect(() => {
    if (location.state) {
      if (location.state.code && location.state.quizid) {
        getByID(location.state.quizid).then((data) => {
          if (data) {
            if (data.exam.type == 'MCQ') {
              setQuestions((prev) => data.exam.paper);
              setAttempt((prev) => data.exam.attemptid);
              setCode((prev) => location.state.code);
              setQuiz((prev) => location.state.quizid);
              UpdateAnswers(location.state.quizid);
            }
          } else {
            navigate('/dashboard');
          }
        });
      } else {
        navigate('/dashboard');
      }
    } else {
      navigate('/dashboard');
    }
  }, []);

  const next = () => {
    if (qIndex != questions.length) {
      if (questions[qIndex].type == 'multichoice') {
        var options = document.getElementsByName('mcq');
        var selectedValue = '';

        for (var i = 0; i < options.length; i++) {
          if (options[i].checked) {
            selectedValue = options[i].value;
            break;
          }
        }

        if (answer.length > 0) {
          let custAnswer = answer;

          if (custAnswer[qIndex]) {
            custAnswer[qIndex] = { name: 'q' + (qIndex + 1), value: selectedValue };
          } else {
            custAnswer.push({ name: 'q' + (qIndex + 1), value: selectedValue });
          }
          update({ id: quizid, answers: answer, code: code, attemptid: attemptid, submit: false, type: 'mcq', offlineTime: moment().toString() }).then((data) => {
            UpdateAnswers(quizid);
            //setAnswers((prev) => custAnswer);
            setQIndex((prev) => qIndex + 1);
          });
        } else {
          let custAnswer = answer;

          if (custAnswer[qIndex]) {
            custAnswer[qIndex] = { name: 'q' + (qIndex + 1), value: selectedValue };
          } else {
            custAnswer.push({ name: 'q' + (qIndex + 1), value: selectedValue });
          }

          getExam(quizid).then((data) => {
            if (!data) {
              add({ id: quizid, answers: answer, code: code, attemptid: attemptid, submit: false, type: 'mcq', offlineTime: moment().toString() }).then((data) => {
                UpdateAnswers(quizid);
                //setAnswers((prev) => custAnswer);
                setQIndex((prev) => qIndex + 1);
              });
            } else {
              update({
                id: quizid,
                answers: answer,
                attemptid: attemptid,
                submit: false,
                type: 'mcq',
                offlineTime: moment().toString(),
              }).then((err) => {
                UpdateAnswers(quizid);
                //setAnswers((prev) => custAnswer);
                setQIndex((prev) => qIndex + 1);
              });
            }
          });
        }
      }

      if (questions[qIndex].type == 'truefalse') {
        var options = document.getElementsByName('mcq');
        var selectedValue = '';

        for (var i = 0; i < options.length; i++) {
          if (options[i].checked) {
            selectedValue = options[i].value;
            break;
          }
        }

        if (answer.length > 0) {
          let custAnswer = answer;

          if (custAnswer[qIndex]) {
            custAnswer[qIndex] = { name: 'q' + (qIndex + 1), value: selectedValue };
          } else {
            custAnswer.push({ name: 'q' + (qIndex + 1), value: selectedValue });
          }
          update({ id: quizid, answers: answer, code: code, attemptid: attemptid, submit: false, type: 'mcq', offlineTime: moment().toString() }).then((err) => {
            UpdateAnswers(quizid);
            //setAnswers((prev) => custAnswer);
            setQIndex((prev) => qIndex + 1);
          });
        } else {
          let custAnswer = answer;

          if (custAnswer[qIndex]) {
            custAnswer[qIndex] = { name: 'q' + (qIndex + 1), value: selectedValue };
          } else {
            custAnswer.push({ name: 'q' + (qIndex + 1), value: selectedValue });
          }

          getExam(quizid).then((data) => {
            if (!data) {
              add({ id: quizid, answers: answer, code: code, attemptid: attemptid, type: 'mcq', offlineTime: moment().toString() }).then((data) => {
                UpdateAnswers(quizid);
                //setAnswers((prev) => custAnswer);
                setQIndex((prev) => qIndex + 1);
              });
            } else {
              update({
                id: quizid,
                answers: answer,
                attemptid: attemptid,
                submit: false,
                type: 'mcq',
                offlineTime: moment().toString(),
              }).then((err) => {
                UpdateAnswers(quizid);
                //setAnswers((prev) => custAnswer);
                setQIndex((prev) => qIndex + 1);
              });
            }
          });
        }
      }
    }
  };

  const back = () => {
    if (qIndex != 0) {
      setQIndex((prev) => qIndex - 1);
    } else if (qIndex == 1) {
      setQIndex((prev) => 0);
    } else {
      setQIndex((prev) => qIndex - 1);
    }
  };

  const submit = async () => {
    setShow((prev) => true);
    if (questions[qIndex].type == 'multichoice') {
      var options = document.getElementsByName('mcq');
      var selectedValue = '';

      for (var i = 0; i < options.length; i++) {
        if (options[i].checked) {
          selectedValue = options[i].value;
          break;
        }
      }

      if (answer.length > 0) {
        let custAnswer = answer;

        if (custAnswer[qIndex] && answer.length == qIndex + 1) {
          custAnswer[qIndex] = { name: 'q' + (qIndex + 1), value: selectedValue };
        } else {
          custAnswer.push({ name: 'q' + (qIndex + 1), value: selectedValue });
        }

        setAnswers((prev) => custAnswer);
        update({ id: quizid, answers: answer, code: code, attemptid: attemptid, submit: true, type: 'mcq', offlineTime: moment().toString() }).catch((err) => console.log(err));
        //add({ id: quizid, answers: answer, attemptid: attemptid });
      } else {
        let custAnswer = answer;

        if (custAnswer[qIndex] && answer.length == qIndex + 1) {
          custAnswer[qIndex] = { name: 'q' + (qIndex + 1), value: selectedValue };
        } else {
          custAnswer.push({ name: 'q' + (qIndex + 1), value: selectedValue });
        }

        setAnswers((prev) => custAnswer);
        getExam(quizid).then((data) => {
          if (!data) {
            add({ id: quizid, answers: answer, code: code, attemptid: attemptid, submit: true, type: 'mcq', offlineTime: moment().toString() });
          } else {
            update({
              id: quizid,
              answers: answer,
              code: code,
              attemptid: attemptid,
              submit: true,
              type: 'mcq',
              offlineTime: moment().toString(),
            }).catch((err) => console.log(err));
          }
        });
      }
    }
    if (questions[qIndex].type == 'truefalse') {
      var options = document.getElementsByName('truefalse');
      var selectedValue = '';

      for (var i = 0; i < options.length; i++) {
        if (options[i].checked) {
          selectedValue = options[i].value;
          break;
        }
      }

      if (answer.length > 0) {
        let custAnswer = answer;

        if (custAnswer[qIndex] && answer.length == qIndex + 1) {
          custAnswer[qIndex] = { name: 'q' + (qIndex + 1), value: selectedValue };
        } else {
          custAnswer.push({ name: 'q' + (qIndex + 1), value: selectedValue });
        }

        setAnswers((prev) => custAnswer);
        update({ id: quizid, answers: answer, code: code, attemptid: attemptid, submit: true, type: 'mcq', offlineTime: moment().toString() }).catch((err) => console.log(err));
        //add({ id: quizid, answers: answer, attemptid: attemptid });
      } else {
        let custAnswer = answer;

        if (custAnswer[qIndex] && answer.length == qIndex + 1) {
          custAnswer[qIndex] = { name: 'q' + (qIndex + 1), value: selectedValue };
        } else {
          custAnswer.push({ name: 'q' + (qIndex + 1), value: selectedValue });
        }

        setAnswers((prev) => custAnswer);
        getExam(quizid).then((data) => {
          if (!data) {
            add({ id: quizid, answers: answer, code: code, attemptid: attemptid, submit: true, offlineTime: moment().toString() });
          } else {
            update({
              id: quizid,
              answers: answer,
              attemptid: attemptid,
              type: 'mcq',
              offlineTime: moment().toString(),
            }).catch((err) => console.log(err));
          }
        });
      }
    }

    if (!navigator.onLine) {
      setShow((prev) => false);
      if (CheckAnswers() == false) {
        setFailed((prev) => error);
        examFailed.click();
      } else {
        const confirm = window.confirm('You are about to submit your examination. Click OK to proceed or cancel');
        if (confirm) {
          examSaved.click();
        }
      }
    } else {
      const data = {
        quizid: quizid,
        code: code,
        attemptid: attemptid,
        answers: answer,
        offlineTime: moment().toString(),
      };

      if (CheckAnswers() == false) {
        setShow((prev) => false);
        setFailed((prev) => error);
        examFailed.click();
      } else {
        const confirm = window.confirm('You are about to submit your examination. Click OK to proceed or cancel');
        if (confirm) {
          await SubmitMCQ(data, navigate, setError, setSuccess, {}, setShow, { examFailed, examSubmitted }, deleteRecord);
          if (error) {
            setFailed((prev) => error);
          }
        } else {
          setShow((prev) => false);
        }
      }
    }
  };

  const CheckAnswers = () => {
    if (answer.length == 0) {
      setError((prev) => 'Please answer all questions ');
      return false;
    } else {
      for (let i = 0; i < answer.length; i++) {
        if (!answer[i].value) {
          setError((prev) => 'Question number ' + (i + 1) + ' is not answered');
          return false;
        }
      }
    }
    return true;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <ButtonsModal />

        {show && <RequestLoader />}

        <ExamSaved />
        <ExamFailed error={failed} />
        <ExamSubmitted />

        <div className="col-2 d-none d-lg-block">
          <DesktopNav />
        </div>

        <div className="col-lg-10 col-12 mb-4">
          <Header />
          <div className="row">
            <div className="col-lg-9 col-12">
              <div className="row align-items-center">
                <div className="col">{code && <p className="bold mt-2">{code}</p>}</div>
                <div className="col d-flex justify-content-end">
                  {questions && questions.length > 0 && (
                    <p>
                      {qIndex + 1} of {questions.length}
                    </p>
                  )}
                </div>
              </div>

              <div className="col">
                {questions && questions.length > 0 && questions[qIndex].type == 'multichoice' && <TypeMCQ answer={answer != null && answer[qIndex] ? answer[qIndex].value : ''} key={qIndex} question={questions[qIndex]} id={qIndex + 1} />}
                {questions && questions.length > 0 && questions[qIndex].type == 'truefalse' && <TypeTrueFalse answer={answer != null && answer[qIndex] ? answer[qIndex].value : ''} key={qIndex} question={questions[qIndex]} id={qIndex + 1} />}
                <div>
                  <ToogleQuestion questions={questions} setQIndex={setQIndex} />
                  <br></br>
                  {qIndex > 0 && (
                    <button onClick={() => back()} className="btn text-white my-2 btn-primary me-2">
                      Back
                    </button>
                  )}
                  {questions && questions.length > 0 && qIndex < questions.length - 1 && (
                    <button onClick={() => next()} style={{ background: 'rgba(0,0,0,.8)' }} className="btn text-white my-2 me-2">
                      Next
                    </button>
                  )}
                  {questions && questions.length == qIndex + 1 && (
                    <button onClick={() => submit()} className="btn btn-success text-white my-2">
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="col-3 d-none d-lg-block">
              <RightNav />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
