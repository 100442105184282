import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const OpenHandedModal = ({ code, assignid }) => {
  const [error, setError] = useState();
  const navigate = useNavigate();

  const startText = () => {
    const declare = document.getElementById('openDeclare');
    if (declare.checked) {
      setError((prev) => '');
      navigate('/submit/text', { state: { code: code, assignid: assignid } });
    } else {
      setError((prev) => 'Fill in declartion');
    }
  };

  const startPdf = () => {
    const declare = document.getElementById('openDeclare');
    if (declare.checked) {
      setError((prev) => '');
      navigate('/submit/pdf', { state: { code: code, assignid: assignid } });
    } else {
      setError((prev) => 'Fill in declartion');
    }
  };

  return (
    <div className="modal fade" id="openHandedModal" tabIndex={-1} aria-labelledby="openHandedModal" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              {code} Examination
            </h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="input-group mb-3">
              <label htmlFor="declare">
                <input type="checkbox" id="openDeclare" className="form-check-input me-2" />I declare that this assignment is my own, original work. Where I have used someone else's work, I have indicated this by using the prescribed style of referencing. Every contribution to, and quotation in, this assignment from the work or works of other people has been referenced according to this style.
              </label>
            </div>

            {error && (
              <div class="alert alert-danger" role="alert">
                {error}
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button onClick={() => startText()} type="button" data-bs-dismiss="modal" className="btn btn-primary">
              Text Submit
            </button>

            <button onClick={() => startPdf()} type="button" data-bs-dismiss="modal" className="btn btn-primary">
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
